import React from 'react'
import { Row, Col } from 'reactstrap'
import OCVContactsEntry from '../OCVContactsEntry/OCVContactsEntry'
import Paper from '@material-ui/core/Paper/Paper'
import Tabs from '@material-ui/core/Tabs/Tabs'
import Tab from '@material-ui/core/Tab/Tab'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import Pagination from '../../../MainComponents/Pagination'
import './OCVContacts.css'

library.add(fab, far, fal, fad, fas)

export default class OCVContacts extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      contactsData: null,
      contactHeaders: [],
      appID: '',
      headerValue: 'all',
      currentPage: 1,
      totalPages: 0,
      currentEntries: [],
      search: '',
    }
  }

  handleHeaderChange = (event: any, headerValue: any) => {
    this.setState({
      headerValue: headerValue,
      currentData: this.state.contactsData[headerValue],
    })
  }

  onPageChanged = (data: {
    currentPage: number
    totalPages: number
    pageLimit: number
  }) => {
    const { currentPage, totalPages, pageLimit } = data
    const { currentData } = this.state
    const offset = (currentPage - 1) * pageLimit
    const currentEntries = currentData.slice(offset, offset + pageLimit)
    this.setState({ currentPage, currentEntries, totalPages })
  }

  handleSearch = (event: any) => {
    this.setState({ search: event.target.value })
  }

  componentDidMount() {
    const { link } = this.props

    try {
      fetch(link, { mode: 'cors' })
        .then((response) => response.json())
        .then((data) => {
          let headers = data['headers']
          let entries = data['entries']
          let completeList: any[] = []
          for (let i = 0; i < data['headers'].length; i++) {
            completeList = completeList.concat(
              data['entries'][data['headers'][i]],
            )
          }
          if (this.props.subtypes.includes('showAllHeader')) {
            headers[0] = 'all'
          } else if (
            headers[headers.length - 1].toString().includes('default') &&
            (entries['default'] == null || entries['default'] === undefined)
          ) {
            headers.pop()
          }
          this.setState({
            headerValue: headers[0],
            contactsData: data['entries'],
            contactHeaders: headers,
            contactHeadersMinusDefault: headers,
            appID: data['appID'],
            currentData: data['entries'][headers[0]],
            completeList,
          })
        })
    } catch (e) {}
  }

  render() {
    const { classes, showSearch } = this.props
    const { search } = this.state

    if (this.state.contactsData === null) {
      return (
        <div className="OCVContactsDiv">
          <ReactLoading
            className={'loading-centered'}
            type={'bars'}
            color={'#000'}
            height={'10%'}
            width={'10%'}
          />
        </div>
      )
    } else {
      const config = this.props?.viewData?.config
      let backgroundImageConfig = undefined

      if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
        backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`
      else if (config?.rgba1 && config?.rgba2)
        backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`
      else if (config?.backgroundImageURL)
        backgroundImageConfig = `url(${config.backgroundImageURL})`

      return (
        <div
          id={this.props.anchorID}
          className={'bg-cover ' + config?.additionalTailwindStyles}
          style={{
            backgroundColor: '#eee',
            backgroundImage: backgroundImageConfig,
          }}
        >
          <Row style={{ padding: '50px 0px' }}>
            {showSearch ? (
              <div className="p-2 w-10/12 sm:w-8/12 mx-auto h-10 mb-8">
                <input
                  type="search"
                  value={search}
                  onChange={this.handleSearch}
                  className="w-full rounded-full p-2"
                  placeholder="Search contact.."
                />
              </div>
            ) : null}
            <Col xs={12} lg={10} className="mt-8">
              <Paper className={classes.contactHeaderRoot}>
                <Tabs
                  value={this.state.headerValue}
                  onChange={this.handleHeaderChange}
                  variant="scrollable"
                  indicatorColor={'primary'}
                  scrollButtons="auto"
                  ScrollButtonComponent={(props) => {
                    if (props.direction === 'left' && props.visible)
                      return (
                        <button
                          aria-label="Left arrow"
                          style={{ width: '10rem' }}
                          {...props}
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={['fas', 'chevron-left']}
                            color="black"
                            className="w-full"
                          />
                        </button>
                      )
                    else if (props.direction === 'right' && props.visible)
                      return (
                        <button
                          aria-label="Right arrow"
                          style={{ width: '10rem' }}
                          {...props}
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={['fas', 'chevron-right']}
                            color="black"
                            className="w-full"
                          />
                        </button>
                      )
                    else return null
                  }}
                >
                  {this.state.contactHeadersMinusDefault.map((item: any) => (
                    <Tab key={item} value={item} id={item} label={item} />
                  ))}
                </Tabs>
              </Paper>
              <div className={classes.contactsContainer}>
                <Row>
                  {this.state.currentData
                    .filter((item: any) =>
                      item?.title?.toLowerCase().includes(search),
                    )
                    .map((item: any, index: any) => (
                      <Col
                        key={item.title + index}
                        xs={12}
                        md={10}
                        lg={10}
                        className="p-0"
                      >
                        <OCVContactsEntry classes={classes} entry={item} />
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8}>
              <Pagination
                totalRecords={this.state.currentData.length}
                pageLimit={50}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            </Col>
          </Row>
        </div>
      )
    }
  }
}
